/**
 * @file
 * Styles for the sidebar
*/

@import "variables";


//
// Products (Categories)
//

#block-views-category-menu-block{
  

  a{
    color: $black;
  }
}
